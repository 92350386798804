<template>
    <div>
        <PublicHeader :totalName="title" />
        <div id="myIframe" :style="'position: relative;margin-top: 44px;width: 100%;height:' + pageHeight + 'px;'">
            <iframe :src="getPageUrl" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
        </div>
        <!-- <div class="iframestyleset">
            <iframe name="iframeMap" id="iframeMapViewComponent" v-bind:src="getPageUrl" width="100%" height="100%"
                frameborder="0" scrolling="no" ref="iframeDom"></iframe>
        </div> -->
    </div>
</template>
  
<script>
import PublicHeader from '@/components/publicHeaderNew'
export default {
    name: '',
    components: { PublicHeader },
    data() {
        return {
            title:  this.$t('commonUse').customerService,
            getPageUrl: '',
            pageHeight: 600
        }
    },
    mounted() {
        this.pageHeight = this.getPageSize()
        this.getPageUrl = this.$route.query.IframePage
    },
    methods: {
        getPageSize() {
            let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            pageHeight = pageHeight - 44
            return pageHeight
        }
    }
}
</script>
  
<style scoped>
.iframestyleset {
    height: 100%;
}
</style>
 
